import React, { useEffect, useRef } from 'react';
import UN from '../Home/UN';
import FadeInComponent from './FadeInComponent';
import './Service.css';
import { useInView } from 'react-intersection-observer';
import '../Home/Home.css'
import UNmobile from '../Home/UNmobile';

const Service = [
  { Title: "Marketing and Communication" },
  { Title: "Strategy and Planning" },
  { Title: "Content development & production" },
  { Title: "Experiential & Events" },
  { Title: "Social, Digital & Performance Marketing" },
  { Title: "Media planning and buying" },
  { Title: "PR & Corporate Communications" },
  { Title: "UX/UI & Web/App Design" },
  { Title: "Market Research" },
  { Title: "CRM" },
  { Title: "Technology" },
  { Title: "AI integration" },
  { Title: "Content monetization solutions" },
  { Title: "Branded Integration / Branded content" },
  { Title: "Management Consultancy" },
  { Title: "Fractional Executive Support" },
  { Title: "Business Coaching" },
  { Title: "Business Consulting & Transformation" },
  { Title: "Sustainability" },
  { Title: "Corporate Governance" },
  { Title: "Training & Development" },

];

function Services() {
  const scrollContainerRef = useRef(null);
  const scrollContainerRef1 = useRef(null);
  const thumbRef = useRef(null);



  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    const thumb = thumbRef.current;

    if (scrollContainer && thumb) {
      const handleScroll = () => {
        const scrollRatio = scrollContainer.scrollTop / (scrollContainer.scrollHeight - scrollContainer.clientHeight);
        const thumbPosition = scrollRatio * (scrollContainer.clientHeight - thumb.clientHeight);
        thumb.style.top = `${thumbPosition}px`;
      };

      scrollContainer.addEventListener('scroll', handleScroll);


      handleScroll();

      return () => {
        scrollContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);
  useEffect(() => {
    const scrollContainer = scrollContainerRef1.current;
    const thumb = thumbRef.current;

    if (scrollContainer && thumb) {
      const handleScroll = () => {
        const scrollRatio = scrollContainer.scrollTop / (scrollContainer.scrollHeight - scrollContainer.clientHeight);
        const thumbPosition = scrollRatio * (scrollContainer.clientHeight - thumb.clientHeight);
        thumb.style.top = `${thumbPosition}px`;
      };

      scrollContainer.addEventListener('scroll', handleScroll);
      handleScroll();

      return () => {
        scrollContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const { ref: ref, inView: inView1 } = useInView({
    triggerOnce: true,
    threshold: 0,
  });
  const { ref: ref2, inView: inView2 } = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  const renderMobile = (item, index) => (
    <div key={index} className="col-12 mt-5">
      <FadeInComponent Text={item.Title} />
    </div>
  )

  const renderServices = (item, index) => (
    <div key={index} className="col-12 d-flex align-items-center justify-content-center">
      {index % 2 === 0 ? (
        <div className="row w-100 d-flex align-items-center justify-content-between">
          <div className="col-5 mt-5" style={{ direction: 'rtl' }}>
            <FadeInComponent Text={item.Title} />
          </div>
          <div className="col-7 text-white"></div>
        </div>
      ) : (
        <div className="row w-100">
          <div className="col-7"></div>
          <div className="col-5 mt-5">
            <FadeInComponent Text={item.Title} />
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className='container-fluid Padding' style={{ backgroundColor: 'var(--blue)' }}>
      <div className="container py-5">
        <div className="row">
          <div className="col-12">
            <div className={`row d-flex align-items-center`}>
              <div className="col-2 col-lg-2 h-100 d-flex align-items-center align-items-lg-center">
                <svg className='d-none d-lg-flex' width="90%" height="100%" viewBox="0 0 228 280" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M238 3.99019H190.027V3.99048H190.011V75.7457C181.801 55.3442 173.807 37.9418 156.657 23.3481C138.652 7.99708 114.608 0.480469 91.1368 0.480469C60.2864 0.480469 37.3764 7.89643 22.4068 22.7284H22.4386C7.47952 37.5391 0 58.9818 0 87.0565V193.354H0.0712085C0.550722 221.751 7.71429 243.098 21.5622 257.396C35.9067 272.207 58.4354 279.638 89.1481 279.691C105.334 279.898 121.394 276.828 136.361 270.665C163.726 259.064 179.061 233.018 186.212 205.288C187.322 200.952 188.391 196.603 189.456 192.265L189.693 191.304C189.693 191.114 189.768 190.911 189.844 190.708C189.889 190.588 189.933 190.468 189.963 190.35V273.796L190.156 273.796H238V3.99019ZM190.011 172.572L190.011 97.9772C188.728 93.1371 187.104 88.3938 185.15 83.7829C178.37 68.0505 169.116 57.0219 157.388 50.6971C145.66 44.3724 131.157 41.1941 113.877 41.1623C99.3633 41.1623 87.7097 43.0163 78.9165 46.7243C70.598 49.983 63.8197 56.2673 59.9421 64.316C56.0752 72.3464 54.147 82.84 54.1576 95.7968V95.8603H54.203V181.022C54.1501 201.077 58.6208 215.655 67.6152 224.755C76.6098 233.855 91.4417 238.406 112.111 238.406C125.792 238.659 139.344 235.726 151.696 229.84C163.202 224.141 172.737 214.267 180.301 200.219C184.799 191.494 188.066 182.19 190.011 172.572Z" fill="#82C809" />
                </svg>
                <svg className='d-flex d-lg-none mt-3' width="100px" height="100%" viewBox="0 0 74 125" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M74.0009 1.88434H59.0818V33.8514C56.5283 24.761 54.0429 17.0073 48.7085 10.5053C43.1104 3.66829 35.6346 0.320557 28.3367 0.320557C18.7446 0.320557 11.6213 3.62346 6.96683 10.2293H6.97672C2.32557 16.8257 0 26.3758 0 38.8796V86.2221H0.0223383C0.171428 98.8694 2.39876 108.377 6.70442 114.745C11.1645 121.341 18.1692 124.651 27.7186 124.675C32.7511 124.767 37.7445 123.4 42.3983 120.655C50.9068 115.488 55.6749 103.888 57.8983 91.5374C58.2432 89.6072 58.5753 87.671 58.9065 85.74L58.907 85.7372L58.9804 85.3091C58.9804 85.2244 59.0039 85.1342 59.0275 85.0436C59.0413 84.9903 59.0552 84.9369 59.0644 84.8844V122.049H59.1243H74.0009V1.88434ZM59.0818 76.949L59.0818 43.7578C58.6826 41.5971 58.1766 39.4797 57.5678 37.4216C55.4597 30.4148 52.5823 25.5029 48.9358 22.6859C45.2893 19.869 40.7799 18.4535 35.4074 18.4394C30.8946 18.4394 27.2712 19.2651 24.5371 20.9165C21.9507 22.3679 19.8431 25.1668 18.6375 28.7515C17.4352 32.3281 16.8357 37.0017 16.839 42.7723V42.8007H16.8533V80.7298C16.8368 89.6618 18.2269 96.1544 21.0235 100.208C23.8201 104.261 28.4317 106.287 34.8583 106.287C39.112 106.4 43.3258 105.094 47.1664 102.472C50.7437 99.9339 53.7083 95.5363 56.0603 89.2796C57.4607 85.3887 58.4774 81.239 59.0818 76.949Z" fill="#82C809" />
                </svg>

              </div>
              <div ref={ref} className={`col-10 col-lg-10 mt-3 fade-left ${inView1 ? 'fade-in-left' : ''} d-flex align-items-center align-items-lg-start justify-content-center h-100`} style={{ lineHeight: 'var(--footer)' }}>
                <div className="row" style={{ fontSize: 'var(--subTitle)', color: 'white' }}>
                  <div className="col-12">
                    Business <br className='d-flex d-lg-none' /> excellence made
                  </div>
                  <div className="col-12 d-none d-lg-flex align-items-center">
                    <UN style={{ width: 'var(--width2)', position: 'absolute' }} /><strong style={{ marginLeft: 'var(--width2)' }}>BELIEVABLY</strong>&nbsp;easy
                  </div>
                  <div className="col-12 d-flex d-lg-none align-items-center">

                    <UNmobile style={{ width: 'var(--width2)', position: 'absolute' }} /><strong style={{ marginLeft: 'var(--width2)' }}>BELIEVABLY</strong>&nbsp;easy
                  </div>
                  <div ref={ref2} className={`col-12 d-none mt-5 d-lg-flex align-items-center justify-content-end fade-right ${inView2 ? 'fade-in-right' : ''}`} style={{ fontSize: 'var(--text)', lineHeight: '25px' }}>
                    <div className="row">
                      <div className="col-auto">

                        Give your business the <strong> Unlmtd </strong> advantage by leveraging diverse <br /> skills with tailored strategies and innovative problem-solving to<br /> address your unique challenges.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-5 d-block d-lg-none" style={{ fontSize: 'var(--text)', lineHeight: '25px', color: 'white' }}>
                Give your business the <strong> Unlmtd </strong> advantage by leveraging diverse skills with tailored strategies and innovative problem-solving to address your unique challenges.
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5 d-flex align-items-center justify-content-center">
          <div className="col-12 d-flex align-items-center justify-content-center">
            <div className="custom-scrollbar-container">
              <div className="custom-scrollbar-track"></div>
              <div className="custom-scrollbar-thumb" ref={thumbRef}></div>
              <div className="row d-none d-lg-flex custom-scrollbar-content align-items-center justify-content-center" ref={scrollContainerRef1}>
                {Service.map(renderServices)}
              </div>
              <div className="row d-flex d-lg-flex custom-scrollbar-content align-items-center justify-content-center" ref={scrollContainerRef}>
                {Service.map(renderMobile)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;

