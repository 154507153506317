import * as React from "react";
const Logo2 = (props) => (

  <svg
    width={190}
    height={72}
    viewBox="0 0 204 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    
  >
    <path
      d="M5.1058 64.0115L4.82235 64.5026C4.39258 64.2352 3.89515 64.0966 3.38904 64.1033C3.07132 64.0883 2.75474 64.151 2.46672 64.286C2.17871 64.421 1.92793 64.6241 1.73614 64.8779C1.37944 65.3625 1.19659 65.9533 1.21712 66.5547C1.20268 66.8708 1.25374 67.1865 1.36711 67.4819C1.48047 67.7774 1.6537 68.0462 1.87589 68.2715C2.08862 68.4726 2.33961 68.6289 2.61396 68.7311C2.8883 68.8333 3.18041 68.8793 3.47289 68.8664C3.9998 68.8743 4.51421 68.7057 4.93412 68.3873L5.2176 68.9063C4.68326 69.372 3.98916 69.6124 3.28123 69.577C2.85733 69.5959 2.43455 69.5201 2.04364 69.355C1.65274 69.19 1.30356 68.9398 1.02149 68.6228C0.528839 68.0333 0.271468 67.2825 0.298856 66.5148C0.283564 66.083 0.359582 65.6528 0.521947 65.2524C0.684312 64.852 0.929362 64.4904 1.24108 64.1912C1.49771 63.9336 1.80347 63.7302 2.14021 63.593C2.47696 63.4558 2.83783 63.3877 3.2014 63.3927C3.88822 63.3756 4.56021 63.594 5.1058 64.0115Z"
      fill="var(--black)"
    />
    <path
      d="M12.0135 66.4908C12.0292 66.9117 11.9595 67.3314 11.8085 67.7245C11.6576 68.1177 11.4285 68.4763 11.1352 68.7785C10.859 69.0442 10.5312 69.2503 10.172 69.3839C9.81282 69.5175 9.43001 69.5758 9.04733 69.5553C8.66465 69.5347 8.29028 69.4356 7.94752 69.2642C7.60476 69.0928 7.30092 68.8527 7.05487 68.5589C6.59203 67.9708 6.35042 67.2388 6.37217 66.4908C6.35376 66.0708 6.41949 65.6513 6.56553 65.2571C6.71157 64.8628 6.93497 64.5018 7.22257 64.1951C7.4782 63.9317 7.78604 63.7246 8.12636 63.5871C8.46668 63.4496 8.83197 63.3847 9.19883 63.3966C9.61374 63.3821 10.0261 63.4673 10.4012 63.6452C10.7764 63.823 11.1034 64.0883 11.3548 64.4187C11.8032 65.0146 12.0355 65.7453 12.0135 66.4908ZM7.3024 66.4908C7.26683 67.1369 7.47322 67.7732 7.88131 68.2754C8.05246 68.4636 8.26219 68.6127 8.4962 68.7125C8.7302 68.8122 8.98296 68.8603 9.23726 68.8535C9.49155 68.8467 9.7414 68.7851 9.96972 68.6729C10.198 68.5608 10.3995 68.4007 10.5603 68.2036C10.9084 67.6856 11.0907 67.074 11.0828 66.45C11.075 65.826 10.8774 65.2192 10.5164 64.7101C10.3598 64.5119 10.1592 64.3528 9.93056 64.2454C9.70189 64.138 9.45138 64.0852 9.19883 64.0913C8.93361 64.084 8.67052 64.1404 8.43157 64.2557C8.19262 64.371 7.98476 64.5419 7.82541 64.7541C7.45683 65.2557 7.27215 65.869 7.3024 66.4908Z"
      fill="var(--black)"
    />
    <path
      d="M14.5051 61.0811V69.4094H13.6387V61.5482L14.5051 61.0811Z"
      fill="var(--black)"
    />
    <path
      d="M17.3829 61.0811V69.4094H16.5205V61.5482L17.3829 61.0811Z"
      fill="var(--black)"
    />
    <path
      d="M24.1623 66.7743H19.9622C19.9628 67.0582 20.0214 67.339 20.1342 67.5996C20.247 67.8601 20.4118 68.0949 20.6184 68.2896C20.8251 68.4843 21.0693 68.6348 21.3361 68.7319C21.6028 68.829 21.8866 68.8707 22.1701 68.8544C22.7268 68.8475 23.2699 68.6812 23.7351 68.3753L24.0306 68.8943C23.4442 69.3475 22.7193 69.5845 21.9784 69.5651C21.5638 69.5839 21.15 69.5114 20.7664 69.3527C20.3829 69.194 20.0388 68.953 19.7586 68.6468C19.2352 68.0585 18.9617 67.2895 18.9961 66.5028C18.9665 65.6684 19.2515 64.8533 19.7946 64.2191C20.0183 63.9556 20.2975 63.7449 20.6123 63.602C20.927 63.4591 21.2695 63.3876 21.6151 63.3927C22.0121 63.3742 22.4068 63.4626 22.7579 63.6487C23.1091 63.8348 23.4038 64.1118 23.6114 64.4507C23.9828 65.0647 24.1673 65.7736 24.1424 66.4908L24.1623 66.7743ZM19.9622 66.0796H23.268C23.2745 65.5524 23.1137 65.0366 22.8089 64.6064C22.6724 64.4328 22.4966 64.2941 22.296 64.2018C22.0954 64.1094 21.8757 64.0661 21.655 64.0754C21.4303 64.0732 21.2081 64.1224 21.0054 64.2193C20.8026 64.3161 20.6247 64.4581 20.4852 64.6343C20.1553 65.0447 19.9713 65.5532 19.9622 66.0796Z"
      fill="var(--black)"
    />
    <path
      d="M30.2191 64.0115L29.9356 64.5026C29.5058 64.2352 29.0084 64.0966 28.5023 64.1033C28.1846 64.0883 27.868 64.151 27.58 64.286C27.292 64.421 27.0412 64.6241 26.8494 64.8779C26.4927 65.3625 26.3099 65.9533 26.3304 66.5547C26.316 66.8708 26.367 67.1865 26.4804 67.4819C26.5938 67.7774 26.767 68.0462 26.9891 68.2715C27.2019 68.4726 27.4529 68.6289 27.7272 68.7311C28.0016 68.8333 28.2937 68.8793 28.5861 68.8664C29.1131 68.8743 29.6275 68.7057 30.0474 68.3873L30.3309 68.9063C29.7965 69.372 29.1024 69.6124 28.3945 69.577C27.9706 69.5959 27.5478 69.5201 27.1569 69.355C26.766 69.19 26.4168 68.9398 26.1348 68.6228C25.6421 68.0333 25.3847 67.2825 25.4121 66.5148C25.3968 66.083 25.4729 65.6528 25.6352 65.2524C25.7976 64.852 26.0426 64.4904 26.3544 64.1912C26.611 63.9336 26.9168 63.7302 27.2535 63.593C27.5902 63.4558 27.9511 63.3877 28.3147 63.3927C29.0015 63.3756 29.6735 63.594 30.2191 64.0115Z"
      fill="var(--black)"
    />
    <path
      d="M33.1613 64.2511V67.9162C33.1613 68.547 33.4168 68.8584 33.9239 68.8584C34.2075 68.8532 34.4856 68.7792 34.7344 68.6428L35.0458 69.1219C34.6575 69.4028 34.1915 69.5562 33.7123 69.5611C33.5154 69.572 33.3185 69.5394 33.1356 69.4656C32.9527 69.3917 32.7884 69.2785 32.6543 69.1339C32.3864 68.7944 32.254 68.3676 32.283 67.9361V64.2511H31.3887V63.5404H32.295V62.2548L33.1454 61.8157V63.5324H34.942V64.2511H33.1613Z"
      fill="var(--black)"
    />
    <path
      d="M36.5431 61.6359C36.5416 61.5614 36.5558 61.4873 36.5847 61.4186C36.6136 61.3498 36.6565 61.2879 36.7107 61.2367C36.7625 61.1832 36.8246 61.1408 36.8932 61.112C36.9618 61.0832 37.0356 61.0685 37.11 61.069C37.1845 61.0681 37.2583 61.0825 37.327 61.1113C37.3957 61.1402 37.4577 61.1828 37.5092 61.2367C37.5635 61.2879 37.6064 61.3498 37.6353 61.4186C37.6641 61.4873 37.6783 61.5614 37.6769 61.6359C37.6778 61.7104 37.6634 61.7843 37.6346 61.853C37.6057 61.9217 37.5631 61.9837 37.5092 62.0352C37.4302 62.1152 37.3291 62.1699 37.2189 62.1923C37.1086 62.2147 36.9942 62.2038 36.8902 62.161C36.7862 62.1181 36.6973 62.0454 36.6348 61.9519C36.5722 61.8584 36.5389 61.7484 36.5391 61.6359H36.5431ZM37.5252 63.5483V69.4093H36.6748V63.5483H37.5252Z"
      fill="var(--black)"
    />
    <path
      d="M41.8772 69.4093H40.9989L38.791 63.5483H39.7213L41.45 68.3912L43.1668 63.5483H44.097L41.8772 69.4093Z"
      fill="var(--black)"
    />
    <path
      d="M50.0621 66.7743H45.882C45.882 67.0584 45.9401 67.3395 46.0528 67.6003C46.1654 67.8611 46.3302 68.0961 46.537 68.2909C46.7437 68.4857 46.9881 68.6362 47.2552 68.7331C47.5222 68.83 47.8063 68.8713 48.0898 68.8544C48.6453 68.8474 49.1871 68.6811 49.6509 68.3753L49.9463 68.8943C49.3609 69.3493 48.6354 69.5865 47.8942 69.5651C47.4796 69.5833 47.066 69.5106 46.6825 69.3519C46.299 69.1933 45.9549 68.9526 45.6744 68.6468C45.1524 68.0577 44.8791 67.2892 44.9118 66.5028C44.8807 65.6682 45.1658 64.8525 45.7103 64.2191C45.934 63.9556 46.2133 63.7449 46.5281 63.602C46.8428 63.4591 47.1853 63.3876 47.5309 63.3927C47.9278 63.3746 48.3223 63.4632 48.6734 63.6493C49.0244 63.8354 49.3192 64.1121 49.5271 64.4507C49.8953 65.0659 50.0783 65.7743 50.0541 66.4909L50.0621 66.7743ZM45.866 66.0796H49.1718C49.1802 65.5521 49.0193 65.0358 48.7127 64.6064C48.5762 64.4328 48.4004 64.2941 48.1998 64.2018C47.9992 64.1095 47.7795 64.0661 47.5588 64.0754C47.3342 64.0738 47.1122 64.1233 46.9095 64.2201C46.7069 64.3169 46.5289 64.4586 46.389 64.6344C46.0607 65.0456 45.8769 65.5535 45.866 66.0796Z"
      fill="var(--black)"
    />
    <path
      d="M59.2171 64.0115L58.9336 64.5026C58.5038 64.2352 58.0064 64.0967 57.5003 64.1034C57.1826 64.0884 56.866 64.1511 56.578 64.286C56.2899 64.421 56.0392 64.6242 55.8474 64.8779C55.4918 65.3629 55.3104 65.9538 55.3324 66.5548C55.3179 66.8709 55.369 67.1865 55.4823 67.482C55.5957 67.7774 55.7689 68.0462 55.9911 68.2715C56.2035 68.4732 56.4544 68.6299 56.7288 68.7321C57.0033 68.8344 57.2956 68.88 57.5881 68.8664C58.115 68.8744 58.6295 68.7057 59.0494 68.3873L59.3328 68.9063C58.7987 69.3724 58.1045 69.6129 57.3965 69.5771C56.9727 69.5954 56.55 69.5193 56.1592 69.3543C55.7684 69.1892 55.4192 68.9394 55.1367 68.6229C54.6441 68.0334 54.3867 67.2826 54.4141 66.5148C54.3996 66.0831 54.4759 65.6532 54.6383 65.2529C54.8006 64.8526 55.0452 64.4908 55.3563 64.1912C55.614 63.9338 55.9205 63.7306 56.2578 63.5935C56.5952 63.4564 56.9565 63.3881 57.3206 63.3927C58.0049 63.3765 58.6741 63.5949 59.2171 64.0115Z"
      fill="var(--black)"
    />
    <path
      d="M66.123 66.4908C66.1386 66.9117 66.0689 67.3314 65.9179 67.7246C65.767 68.1177 65.5379 68.4763 65.2446 68.7785C64.9685 69.0436 64.6409 69.2491 64.2821 69.3823C63.9233 69.5156 63.541 69.5737 63.1588 69.5531C62.7766 69.5325 62.4027 69.4337 62.0603 69.2627C61.7179 69.0916 61.4143 68.8521 61.1683 68.5589C60.704 67.9714 60.461 67.2394 60.4816 66.4908C60.463 66.0704 60.5291 65.6506 60.6759 65.2563C60.8226 64.8619 61.0471 64.5011 61.336 64.1951C61.5905 63.9314 61.8976 63.7241 62.2374 63.5866C62.5771 63.449 62.9419 63.3843 63.3083 63.3966C63.7233 63.3814 64.1358 63.4664 64.5111 63.6443C64.8863 63.8222 65.2132 64.0878 65.4642 64.4187C65.9126 65.0146 66.1449 65.7454 66.123 66.4908ZM61.4118 66.4908C61.3763 67.137 61.5826 67.7732 61.9907 68.2755C62.1623 68.4637 62.3723 68.6129 62.6066 68.7126C62.841 68.8124 63.0941 68.8606 63.3487 68.8537C63.6033 68.8469 63.8534 68.7853 64.082 68.6731C64.3107 68.5609 64.5125 68.4008 64.6737 68.2036C65.0228 67.6859 65.2054 67.0738 65.1968 66.4494C65.1883 65.825 64.989 65.2181 64.6258 64.7102C64.4692 64.5119 64.2686 64.3528 64.04 64.2454C63.8113 64.138 63.5608 64.0852 63.3083 64.0913C63.043 64.0841 62.7799 64.1404 62.541 64.2558C62.302 64.3711 62.0942 64.5419 61.9348 64.7541C61.568 65.2566 61.3835 65.8693 61.4118 66.4908Z"
      fill="var(--black)"
    />
    <path
      d="M72.6827 69.4094H71.8323V65.9758C71.8323 64.7541 71.2999 64.1433 70.2353 64.1433C69.9163 64.1447 69.6021 64.2199 69.317 64.3629C69.0423 64.4863 68.8033 64.6772 68.6223 64.9178V69.4094H67.752V63.5484H68.4746L68.5505 64.1672H68.5904C68.8385 63.9133 69.1365 63.7133 69.4656 63.5801C69.7947 63.4468 70.1478 63.383 70.5028 63.3927C71.956 63.3927 72.6827 64.2324 72.6827 65.9119V69.4094Z"
      fill="var(--black)"
    />
    <path
      d="M77.7057 64.4267C77.2715 64.2022 76.7891 64.087 76.3003 64.0913C76.0169 64.0762 75.7363 64.1548 75.5018 64.3149C75.4073 64.3863 75.3314 64.4795 75.2807 64.5866C75.2299 64.6936 75.2058 64.8114 75.2104 64.9298C75.2037 65.0492 75.2239 65.1686 75.2695 65.2791C75.3151 65.3897 75.3849 65.4886 75.4739 65.5685C75.7807 65.7801 76.1248 65.9319 76.488 66.0157C77.0266 66.1445 77.5298 66.3913 77.9612 66.7383C78.1016 66.8877 78.2095 67.0644 78.2782 67.2575C78.3469 67.4506 78.3749 67.6558 78.3605 67.8602C78.367 68.0964 78.3185 68.3308 78.2187 68.545C78.1189 68.7591 77.9706 68.947 77.7856 69.0939C77.3648 69.4215 76.8411 69.5885 76.3083 69.565C75.5669 69.5806 74.8407 69.3535 74.2402 68.9182L74.372 68.6987C74.418 68.61 74.47 68.5247 74.5277 68.4431C75.0173 68.7101 75.567 68.8475 76.1247 68.8424C77.027 68.8424 77.4781 68.503 77.4781 67.8243C77.4832 67.7017 77.4598 67.5795 77.41 67.4673C77.3601 67.3551 77.2851 67.256 77.1907 67.1775C76.8624 66.9643 76.4966 66.8152 76.1127 66.7383C74.8857 66.395 74.2735 65.7961 74.2762 64.9417C74.2719 64.7256 74.3185 64.5115 74.4122 64.3166C74.5058 64.1218 74.6438 63.9516 74.8152 63.8198C75.2068 63.5159 75.6933 63.3604 76.1886 63.3807C76.826 63.3719 77.4496 63.5663 77.9692 63.9356L77.7057 64.4267Z"
      fill="var(--black)"
    />
    <path
      d="M84.7163 63.5483V69.4093H84.0456L83.9697 68.7386H83.9298C83.6842 68.9975 83.3872 69.2022 83.0579 69.3397C82.7286 69.4772 82.3742 69.5444 82.0174 69.5371C81.7351 69.5488 81.4539 69.4947 81.1961 69.379C80.9383 69.2632 80.711 69.0891 80.5322 68.8703C80.145 68.3391 79.9545 67.6899 79.9932 67.0338V63.5204H80.8556V66.8781C80.8556 68.145 81.3294 68.7772 82.2769 68.7745C82.5854 68.7708 82.8887 68.6956 83.1632 68.5549C83.4391 68.4243 83.68 68.23 83.8659 67.988V63.5483H84.7163Z"
      fill="var(--black)"
    />
    <path
      d="M87.5948 61.0811V69.4094H86.7324V61.5482L87.5948 61.0811Z"
      fill="var(--black)"
    />
    <path
      d="M90.901 64.2511V67.9162C90.901 68.547 91.1565 68.8584 91.6635 68.8584C91.957 68.8565 92.2453 68.781 92.502 68.6388L92.8134 69.1179C92.4262 69.3982 91.9618 69.5517 91.4839 69.5571C91.2863 69.5686 91.0886 69.5363 90.905 69.4625C90.7214 69.3886 90.5564 69.275 90.4219 69.1299C90.1539 68.7904 90.0216 68.3636 90.0506 67.9321V64.2511H89.1562V63.5404H90.0626V62.2548L90.913 61.8157V63.5324H92.7096V64.2511H90.901Z"
      fill="var(--black)"
    />
    <path
      d="M98.6508 69.4093H98.004L97.8763 68.7785C97.6204 69.0382 97.3143 69.243 96.9765 69.3803C96.6387 69.5176 96.2765 69.5845 95.912 69.577C95.3912 69.6026 94.8811 69.4235 94.4906 69.0779C94.3141 68.9076 94.1758 68.7017 94.0849 68.4739C93.994 68.246 93.9527 68.0015 93.9636 67.7564C93.9636 66.4256 94.9445 65.7602 96.9061 65.7602H97.8164V65.6843C97.8164 64.6596 97.3173 64.1472 96.3192 64.1472C95.7259 64.1572 95.1512 64.3557 94.6783 64.7141L94.3948 64.239C94.9877 63.7036 95.7643 63.4176 96.5627 63.4405C96.8546 63.4218 97.1468 63.4692 97.4178 63.5791C97.6888 63.689 97.9315 63.8586 98.1278 64.0753C98.5215 64.5973 98.7142 65.2435 98.6708 65.8959L98.6508 69.4093ZM97.8164 66.363H97.0179C95.6046 66.363 94.9019 66.7982 94.9019 67.6646C94.8928 67.825 94.9172 67.9857 94.9735 68.1362C95.0299 68.2867 95.117 68.4239 95.2293 68.5389C95.4827 68.7622 95.8145 68.8756 96.1515 68.8543C96.4997 68.8544 96.842 68.765 97.1457 68.5948C97.4294 68.4581 97.6607 68.2324 97.8044 67.952L97.8164 66.363Z"
      fill="var(--black)"
    />
    <path
      d="M105.569 69.4094H104.719V65.9759C104.719 64.7542 104.186 64.1433 103.122 64.1433C102.804 64.1447 102.491 64.2199 102.208 64.3629C101.932 64.4863 101.691 64.6772 101.509 64.9178V69.4094H100.646V63.5484H101.369L101.445 64.1672H101.485C101.733 63.9138 102.03 63.7142 102.358 63.5809C102.687 63.4476 103.039 63.3835 103.393 63.3927C104.849 63.3927 105.577 64.2325 105.577 65.912L105.569 69.4094Z"
      fill="var(--black)"
    />
    <path
      d="M111.949 64.0116L111.662 64.5026C111.232 64.2348 110.735 64.0962 110.229 64.1034C109.912 64.0887 109.596 64.1516 109.308 64.2865C109.021 64.4215 108.771 64.6245 108.58 64.8779C108.221 65.3617 108.038 65.9531 108.061 66.5548C108.046 66.8709 108.097 67.1866 108.211 67.482C108.324 67.7774 108.497 68.0462 108.719 68.2715C108.932 68.4731 109.183 68.6296 109.457 68.7318C109.732 68.834 110.024 68.8798 110.316 68.8664C110.842 68.875 111.356 68.7062 111.774 68.3873L112.057 68.9063C111.524 69.3732 110.829 69.6138 110.121 69.5771C109.697 69.5954 109.274 69.5193 108.884 69.3543C108.493 69.1893 108.144 68.9394 107.861 68.6229C107.37 68.0325 107.113 67.2823 107.138 66.5148C107.124 66.0831 107.2 65.6532 107.363 65.2529C107.525 64.8526 107.77 64.4909 108.081 64.1912C108.338 63.9339 108.645 63.7307 108.982 63.5935C109.32 63.4564 109.681 63.3881 110.045 63.3927C110.732 63.3763 111.404 63.5946 111.949 64.0116Z"
      fill="var(--black)"
    />
    <path
      d="M118.302 63.5483L115.742 70.2996C115.343 71.3497 114.824 71.8966 114.145 71.8966C113.677 71.8823 113.225 71.7187 112.856 71.4295L113.139 70.9664C113.375 71.1053 113.641 71.1848 113.914 71.198C114.345 71.198 114.712 70.7987 115.036 70.0002L115.271 69.4333H115.052L112.792 63.5723H113.706L115.567 68.5429L117.379 63.5483H118.302Z"
      fill="var(--black)"
    />
    <path
      d="M126.961 65.1613H127.891V65.6643C127.891 66.729 127.727 67.5129 127.4 68.0159L128.626 69.4093H127.556L126.933 68.7106C126.677 68.9984 126.36 69.2247 126.005 69.3727C125.649 69.5206 125.265 69.5863 124.881 69.565C124.531 69.5775 124.183 69.5188 123.857 69.3925C123.531 69.2662 123.234 69.075 122.984 68.8304C122.775 68.6215 122.611 68.3721 122.502 68.0975C122.393 67.8228 122.341 67.5288 122.35 67.2334C122.352 66.7796 122.474 66.3346 122.705 65.9438C122.934 65.5385 123.26 65.1967 123.655 64.9497L123.487 64.7541C122.869 64.0673 122.557 63.4445 122.557 62.8935C122.549 62.6452 122.595 62.398 122.693 62.1696C122.791 61.9412 122.938 61.7372 123.124 61.572C123.537 61.2199 124.067 61.0373 124.609 61.061C125.234 61.0773 125.839 61.285 126.342 61.6559L125.967 62.2508C125.548 62.0176 125.077 61.8927 124.597 61.8874C124.307 61.8759 124.022 61.9726 123.799 62.1589C123.7 62.2457 123.621 62.3534 123.569 62.4742C123.516 62.595 123.491 62.726 123.495 62.8576C123.495 63.3048 123.8 63.877 124.41 64.5744L126.805 67.2853C126.947 66.8203 127 66.3326 126.961 65.848V65.1613ZM124.166 65.4966C123.922 65.6742 123.727 65.9104 123.599 66.1834C123.449 66.4566 123.369 66.7622 123.364 67.0737C123.354 67.2924 123.388 67.5108 123.464 67.7164C123.539 67.9219 123.654 68.1106 123.803 68.2714C123.958 68.4226 124.143 68.5406 124.345 68.6182C124.548 68.6958 124.764 68.7313 124.981 68.7226C125.248 68.7302 125.513 68.6732 125.753 68.5564C125.994 68.4397 126.203 68.2666 126.362 68.0518L124.166 65.4966Z"
      fill="var(--black)"
    />
    <path
      d="M141.71 69.4093H140.843V66.0517C140.843 64.7794 140.311 64.1433 139.246 64.1433C138.943 64.1518 138.645 64.2225 138.37 64.3509C138.096 64.4793 137.85 64.6627 137.649 64.8898C137.739 65.2175 137.782 65.5563 137.777 65.896V69.4093H136.927V65.9998C136.927 64.7621 136.404 64.1433 135.366 64.1433C135.066 64.1499 134.772 64.2265 134.507 64.3668C134.235 64.4958 133.998 64.689 133.817 64.9298V69.4093H132.95V63.5484H133.673L133.749 64.1552H133.789C134.288 63.6648 134.961 63.3908 135.661 63.3927C136.015 63.3615 136.371 63.434 136.685 63.6013C136.999 63.7685 137.258 64.0234 137.43 64.3349C137.695 64.0316 138.023 63.79 138.391 63.6272C138.76 63.4644 139.159 63.3843 139.562 63.3927C139.867 63.3745 140.172 63.4266 140.454 63.545C140.735 63.6634 140.986 63.8448 141.187 64.0754C141.57 64.6018 141.758 65.2458 141.718 65.896L141.71 69.4093Z"
      fill="var(--black)"
    />
    <path
      d="M147.957 69.4093H147.322L147.195 68.7785C146.939 69.0386 146.633 69.2436 146.295 69.381C145.957 69.5183 145.595 69.585 145.23 69.577C144.711 69.6015 144.202 69.4225 143.813 69.0779C143.635 68.9084 143.496 68.7028 143.404 68.4748C143.312 68.2468 143.271 68.0018 143.282 67.7564C143.282 66.4256 144.263 65.7601 146.225 65.7601H147.119V65.6843C147.119 64.6582 146.616 64.1472 145.618 64.1472C145.026 64.158 144.453 64.3565 143.981 64.7141L143.693 64.239C144.287 63.7027 145.065 63.4166 145.865 63.4405C146.156 63.4218 146.448 63.4692 146.718 63.5792C146.989 63.6891 147.231 63.8587 147.426 64.0753C147.822 64.5965 148.015 65.2433 147.969 65.8959L147.957 69.4093ZM147.107 66.363H146.308C144.898 66.363 144.192 66.7968 144.192 67.6645C144.183 67.825 144.208 67.9857 144.264 68.1362C144.32 68.2867 144.407 68.4239 144.52 68.5389C144.646 68.6498 144.793 68.7347 144.951 68.7888C145.11 68.8429 145.278 68.8652 145.446 68.8543C145.794 68.8544 146.136 68.765 146.44 68.5948C146.722 68.4555 146.953 68.2305 147.099 67.952L147.107 66.363Z"
      fill="var(--black)"
    />
    <path
      d="M154.876 69.4094H154.026V65.9758C154.026 64.7541 153.493 64.1433 152.429 64.1433C152.11 64.1447 151.795 64.2199 151.51 64.3629C151.236 64.4875 150.998 64.6781 150.816 64.9178V69.4094H149.949V63.5484H150.672L150.748 64.1672H150.788C151.036 63.9133 151.334 63.7133 151.663 63.5801C151.992 63.4468 152.345 63.383 152.7 63.3927C154.153 63.3927 154.88 64.2324 154.88 65.9119L154.876 69.4094Z"
      fill="var(--black)"
    />
    <path
      d="M161.129 69.4093H160.498L160.37 68.7785C160.115 69.0382 159.808 69.243 159.471 69.3803C159.133 69.5176 158.771 69.5845 158.406 69.577C157.885 69.6026 157.375 69.4235 156.985 69.0779C156.808 68.9076 156.67 68.7017 156.579 68.4739C156.488 68.246 156.447 68.0015 156.458 67.7564C156.458 66.4256 157.439 65.7602 159.4 65.7602H160.291V65.6843C160.291 64.6596 159.791 64.1472 158.793 64.1472C158.2 64.1572 157.625 64.3557 157.152 64.7141L156.869 64.239C157.462 63.7036 158.238 63.4176 159.037 63.4405C159.329 63.4218 159.621 63.4692 159.892 63.5791C160.163 63.689 160.406 63.8586 160.602 64.0753C160.996 64.5973 161.188 65.2435 161.145 65.8959L161.129 69.4093ZM160.275 66.363H159.476C158.063 66.363 157.36 66.7982 157.36 67.6646C157.351 67.825 157.375 67.9857 157.432 68.1362C157.488 68.2867 157.575 68.4239 157.687 68.5389C157.941 68.7622 158.273 68.8756 158.61 68.8543C158.958 68.8544 159.3 68.765 159.604 68.5948C159.888 68.4581 160.119 68.2324 160.263 67.952L160.275 66.363Z"
      fill="var(--black)"
    />
    <path
      d="M167.245 64.1432L167.377 63.5483H168.048V68.9302C168.048 70.8945 167.191 71.8766 165.477 71.8766C164.688 71.8876 163.925 71.6026 163.337 71.0781L163.632 70.5991C164.129 70.9574 164.725 71.1514 165.337 71.154C165.596 71.1757 165.856 71.1397 166.099 71.0485C166.342 70.9574 166.562 70.8134 166.742 70.627C167.086 70.1373 167.247 69.5424 167.197 68.9462V68.8304C166.984 69.0787 166.718 69.2754 166.418 69.4054C166.117 69.5355 165.791 69.5955 165.465 69.5809C165.075 69.5949 164.688 69.5157 164.335 69.3497C163.982 69.1837 163.674 68.9359 163.436 68.6268C162.977 67.9563 162.753 67.1521 162.8 66.3406C162.847 65.5291 163.162 64.7562 163.696 64.1432C163.938 63.9021 164.227 63.7126 164.545 63.5864C164.863 63.4601 165.203 63.3996 165.544 63.4086C165.88 63.4112 166.212 63.4832 166.519 63.6202C166.802 63.7296 167.052 63.9097 167.245 64.1432ZM167.197 67.8482V65.0535C167.069 64.7692 166.856 64.5319 166.587 64.3748C166.218 64.1422 165.777 64.0543 165.348 64.1281C164.919 64.2018 164.532 64.4319 164.263 64.774C163.888 65.2641 163.697 65.8703 163.724 66.4868C163.682 67.154 163.893 67.8126 164.315 68.3313C164.601 68.6263 164.98 68.8137 165.388 68.8617C165.796 68.9097 166.208 68.8154 166.555 68.5948C166.839 68.4145 167.063 68.153 167.197 67.8442V67.8482Z"
      fill="var(--black)"
    />
    <path
      d="M174.827 66.7743H170.643C170.643 67.0584 170.701 67.3395 170.814 67.6003C170.926 67.8611 171.091 68.0961 171.298 68.2909C171.505 68.4857 171.749 68.6362 172.016 68.7331C172.283 68.83 172.567 68.8713 172.851 68.8544C173.406 68.8481 173.948 68.6817 174.412 68.3753L174.711 68.8943C174.125 69.347 173.4 69.5838 172.659 69.5651C172.244 69.5834 171.83 69.5106 171.446 69.352C171.061 69.1934 170.717 68.9527 170.435 68.6468C169.914 68.0573 169.642 67.2887 169.677 66.5028C169.645 65.6681 169.93 64.8523 170.475 64.2191C170.699 63.9556 170.978 63.7449 171.293 63.602C171.608 63.4591 171.95 63.3876 172.296 63.3927C172.693 63.3742 173.087 63.4626 173.439 63.6487C173.79 63.8348 174.084 64.1118 174.292 64.4507C174.664 65.0647 174.848 65.7736 174.823 66.4908L174.827 66.7743ZM170.631 66.0796H173.937C173.943 65.5524 173.782 65.0366 173.478 64.6064C173.34 64.4327 173.164 64.294 172.962 64.2017C172.761 64.1094 172.541 64.0661 172.32 64.0754C172.096 64.0732 171.874 64.1224 171.672 64.2193C171.47 64.3162 171.293 64.4582 171.154 64.6343C170.822 65.0438 170.638 65.5528 170.631 66.0796Z"
      fill="var(--black)"
    />
    <path
      d="M185.219 69.4093H184.353V66.0516C184.353 64.7794 183.821 64.1432 182.756 64.1432C182.453 64.152 182.155 64.2228 181.88 64.3512C181.605 64.4796 181.36 64.6629 181.159 64.8898C181.25 65.2173 181.295 65.556 181.291 65.8959V69.4093H180.436V65.9997C180.436 64.7621 179.916 64.1432 178.875 64.1432C178.575 64.1569 178.281 64.2353 178.013 64.3731C177.746 64.511 177.512 64.7049 177.326 64.9417V69.4213H176.468V63.5483H177.19L177.27 64.1552H177.31C177.808 63.665 178.48 63.391 179.179 63.3926C179.533 63.361 179.889 63.4332 180.203 63.6006C180.517 63.7679 180.776 64.0231 180.947 64.3349C181.213 64.0322 181.541 63.791 181.909 63.6283C182.278 63.4656 182.677 63.3851 183.079 63.3926C183.385 63.3738 183.691 63.4257 183.973 63.5441C184.256 63.6624 184.507 63.8442 184.708 64.0754C185.091 64.6023 185.277 65.2462 185.235 65.8959L185.219 69.4093Z"
      fill="var(--black)"
    />
    <path
      d="M191.935 66.7743H187.751C187.751 67.0584 187.809 67.3395 187.922 67.6003C188.035 67.8611 188.199 68.0961 188.406 68.2909C188.613 68.4857 188.857 68.6362 189.124 68.7331C189.391 68.83 189.675 68.8713 189.959 68.8544C190.515 68.8481 191.057 68.6817 191.52 68.3753L191.819 68.8943C191.232 69.3475 190.505 69.5844 189.763 69.5651C189.349 69.583 188.935 69.5102 188.552 69.3515C188.168 69.1929 187.824 68.9524 187.544 68.6468C187.022 68.0577 186.748 67.2892 186.781 66.5028C186.75 65.6682 187.035 64.8525 187.579 64.2191C187.803 63.9556 188.082 63.7449 188.397 63.602C188.712 63.4591 189.054 63.3876 189.4 63.3927C189.797 63.3742 190.192 63.4626 190.543 63.6487C190.894 63.8348 191.189 64.1118 191.396 64.4507C191.768 65.0647 191.952 65.7736 191.927 66.4908L191.935 66.7743ZM187.739 66.0796H191.045C191.051 65.5524 190.891 65.0366 190.586 64.6064C190.448 64.4327 190.272 64.294 190.071 64.2017C189.869 64.1094 189.649 64.0661 189.428 64.0754C189.204 64.0732 188.982 64.1224 188.78 64.2193C188.578 64.3162 188.401 64.4582 188.262 64.6343C187.931 65.0438 187.746 65.5528 187.739 66.0796Z"
      fill="var(--black)"
    />
    <path
      d="M198.494 69.4094H197.628V65.9758C197.628 64.7541 197.097 64.1433 196.031 64.1433C195.713 64.1447 195.4 64.2199 195.117 64.3629C194.841 64.4863 194.601 64.6771 194.418 64.9178V69.4094H193.552V63.5484H194.274L194.354 64.1672H194.394C194.642 63.9134 194.939 63.7135 195.267 63.5802C195.596 63.4469 195.948 63.383 196.303 63.3927C197.756 63.3927 198.486 64.2311 198.486 65.9119L198.494 69.4094Z"
      fill="var(--black)"
    />
    <path
      d="M201.732 64.2511V67.9162C201.732 68.547 201.987 68.8584 202.494 68.8584C202.788 68.8565 203.076 68.781 203.333 68.6388L203.644 69.1179C203.257 69.3982 202.793 69.5517 202.315 69.5571C202.117 69.5686 201.92 69.5363 201.736 69.4625C201.552 69.3886 201.387 69.275 201.253 69.1299C200.985 68.7904 200.853 68.3636 200.882 67.9321V64.2511H199.991V63.5404H200.894V62.2548L201.744 61.8157V63.5324H203.541V64.2511H201.732Z"
      fill="var(--black)"
    />
    <path d="M75.9375 0H68.252V49.6905H75.9375V0Z" fill="var(--black)" />
    <path
      d="M83.627 11.714H90.2704L90.7256 27.3566H91.3125V49.6905H83.627V11.714ZM109.418 19.9625C108.927 18.8418 108.032 17.9469 106.911 17.4552C105.495 16.8799 103.975 16.6078 102.448 16.6567C100.105 16.6567 98.141 17.1025 96.5546 17.9942C94.9683 18.8858 93.72 20.4642 92.8097 22.7293C91.8994 24.989 91.3777 28.0499 91.2446 31.912H90.0748C90.2451 26.7031 90.9611 22.5456 92.2228 19.4395C93.4844 16.3333 95.2145 14.1295 97.413 12.8279C99.6301 11.5237 102.345 10.8716 105.558 10.8716C109.726 10.8716 112.831 11.9256 114.872 14.0336C116.914 16.1417 117.94 19.1733 117.95 23.1285V49.6985H110.193V24.3582C110.239 22.8544 109.967 21.3578 109.394 19.9665M136.1 19.9665C135.607 18.8467 134.713 17.9522 133.593 17.4592C132.443 16.9402 130.913 16.674 129.002 16.6607C126.654 16.6607 124.699 17.1065 123.137 17.9982C121.574 18.8898 120.344 20.4669 119.448 22.7293C118.537 24.989 118.017 28.0499 117.886 31.912H116.844C116.89 26.7031 117.563 22.5456 118.865 19.4395C120.166 16.3333 121.914 14.1295 124.107 12.8279C126.3 11.5237 129.003 10.8716 132.215 10.8716C136.384 10.8716 139.478 11.9256 141.498 14.0336C143.518 16.1417 144.528 19.1733 144.528 23.1285V49.6985H136.911V24.3582C136.955 22.8545 136.683 21.3584 136.112 19.9665"
      fill="var(--black)"
    />
    <path
      d="M147.322 8.85136H168.035V14.5806H147.322V8.85136ZM152.844 1.96832L160.529 0.0159912V49.6905H152.844V1.96832Z"
      fill="var(--black)"
    />
    <path
      d="M190.841 13.0993C193.053 14.5526 194.735 16.6819 195.887 19.4873C197.04 22.2927 197.615 25.8859 197.612 30.267C197.612 34.7439 197.058 38.4463 195.951 41.3741C194.844 44.3019 193.151 46.5271 190.873 48.0495C188.594 49.572 185.826 50.3319 182.568 50.3293C179.398 50.3293 176.695 49.6346 174.46 48.2452C172.224 46.8558 170.53 44.7172 169.377 41.8293C168.227 38.9414 167.652 35.2829 167.652 30.8539C167.652 26.3823 168.227 22.6707 169.377 19.7189C170.527 16.7671 172.225 14.5606 174.471 13.0993C176.707 11.6434 179.453 10.9155 182.708 10.9155C185.923 10.9155 188.638 11.6434 190.853 13.0993M176.392 19.8387C174.718 22.0984 173.882 25.7023 173.885 30.6503C173.885 35.5557 174.708 39.1277 176.356 41.3661C178.003 43.6046 180.588 44.7225 184.109 44.7198C186.505 44.7198 188.524 44.2101 190.166 43.1907C191.869 42.1037 193.172 40.4921 193.879 38.5993C194.701 36.5552 195.114 33.9055 195.117 30.6503C195.117 27.3525 194.693 24.6602 193.847 22.5735C193.146 20.6689 191.842 19.0455 190.134 17.9502C188.505 16.9521 186.474 16.453 184.041 16.453C180.611 16.453 178.061 17.5816 176.392 19.8387ZM196.314 0H204V49.6905H196.314V0Z"
      fill="var(--black)"
    />
    <path
      d="M58.2064 14.3689C56.1037 12.2848 52.8805 11.2428 48.5366 11.2428C45.18 11.1994 41.9151 12.3386 39.314 14.4607C36.9185 16.5168 35.7767 18.9642 34.6069 21.8348V11.7378H26.7736V35.3454C26.4991 36.7345 26.0314 38.0784 25.3842 39.3379C24.3196 41.3155 22.9741 42.7049 21.3478 43.506C19.6029 44.3337 17.6892 44.7438 15.7583 44.7038C12.8491 44.7038 10.7651 44.0623 9.50611 42.7794C8.24714 41.4965 7.61633 39.4457 7.61367 36.627V11.7139H0V37.9485C0 42.1592 1.00877 45.308 3.02631 47.3947C5.04385 49.4815 8.22451 50.5235 12.5683 50.5208C14.8463 50.5515 17.1067 50.1181 19.2118 49.2472C23.0646 47.6183 25.2205 43.9532 26.2266 40.0645C26.3983 39.4097 26.558 38.755 26.7177 38.0962C26.7177 38.0563 26.7457 38.0084 26.7576 37.9645V49.7064H34.5909V25.0408C34.7755 24.3338 35.0117 23.6412 35.2975 22.9687C36.2531 20.7515 37.556 19.1985 39.2062 18.3095C40.8564 17.4205 42.8966 16.976 45.3267 16.976C47.3655 16.976 49.0051 17.2421 50.2454 17.7745C51.4166 18.2323 52.3709 19.1168 52.9164 20.2498C53.507 21.6484 53.7797 23.1607 53.7149 24.6775V49.7064H61.3645V23.4238C61.3645 19.4713 60.3118 16.453 58.2064 14.3689Z"
      fill="#83C909"
    />
    <path
      d="M34.6067 11.7379H26.7734V49.6905H34.6067V11.7379Z"
      fill="#83C909"
    />
  </svg>
  
);
export default Logo2;
