import React from 'react';

function Cookie() {
    return (
        <div className="terms-container" style={{ paddingTop: '10rem', backgroundColor: 'var(--blue)' }}>
            <embed
                src="/cookies.pdf"
                type="application/pdf"
                width="100%"
                height="800px"
            />
        </div>
    );
}

export default Cookie;
