import React, { useState } from 'react';
import Logo from '../Logo/Logo';
import { Fade as Hamburger } from 'hamburger-react';
import Logo2 from '../Logo/Logo2';
import { useNavigate } from 'react-router-dom';

const links = [
    {
        Title: 'ABOUT',
        Link: '/#about'
    },
    {
        Title: 'SERVICES',
        Link: '/#services'
    },
    {
        Title: 'MEMBERS',
        Link: '/#members'
    },
    {
        Title: 'CLIENTS',
        Link: '/#clients'
    },
    {
        Title: 'CONTACT',
        Link: '/#contact'
    },
];

function Navbar() {
    const [isOpen, setOpen] = useState(false);

    const navigate = useNavigate();
    const renderLinks = (item, index) => (
        <div className="col-auto d-none d-lg-flex Link" key={index}>
            <a href={item.Link} style={{ color: 'inherit', textDecoration: 'none' }}>{item.Title}</a>
        </div>
    );

    const renderMobile = (item, index) => (
        <div key={index} className="col-6 d-flex d-lg-none py-2">
            <a href={item.Link} style={{ color: 'inherit', textDecoration: 'none' }}>{item.Title}</a>
        </div>
    );

    return (
        <div className='container-fluid px-3' style={{ position: 'absolute', top: '0', backgroundColor: isOpen ? 'white' : 'transparent', transition: '0.2s ease-in-out' }}>
            <div className="container py-4">
                <div className="row">
                    <div className="col-12">
                        <div className="row d-flex align-items-center" style={{ color: 'white', }}>
                            <div  className="col ml-auto py-3" style={{ color: 'blue' }}>
                                {isOpen ? (

                                    <Logo2 onClick={() => navigate('/')} style={{cursor:'pointer'}}/>
                                ) : (

                                    <Logo onClick={() => navigate('/')} style={{cursor:'pointer'}} />
                                )
                                }
                            </div>

                            {links.map(renderLinks)}

                            <div className="col-auto d-lg-none ml-auto">
                                <Hamburger toggled={isOpen} toggle={setOpen} color={isOpen ? 'var(--black)' : 'white'} style={{ position: 'relative', zIndex: '4' }} />
                            </div>
                        </div>
                    </div>
                </div>
                {isOpen && (

                    <div className="row d-flex py-3 d-lg-none mobile-menu mt-3" style={{ backgroundColor: 'transparent', color: 'var(--black)', fontSize: '20px', borderTop: '1px solid var(--green)' }}>

                        {links.map(renderMobile)}
                    </div>
                )}

            </div>
        </div>
    );
}

export default Navbar;
