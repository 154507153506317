import React, { useEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import './Collective.css';
import { RequestManager } from '../Requests/RequestManager';
import Background from './Media/Subtract.png';
import TransformMany from '../Transformer/TransformMany';

function Collective() {
    const [collective, setCollective] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // State for loading
    const swiperTopRef = useRef(null); // Create ref for top Swiper
    const swiperBottomRef = useRef(null); // Create ref for bottom Swiper

    const renderCollective = (item, index) => (
        <div key={index} className="d-flex align-items-center justify-content-center" style={{
            backgroundImage: `url(${Background})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'left',
            height: 'var(--height)',
        }}>
            <div className="d-flex Hov px-5 align-items-center justify-content-center"
                style={{
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    cursor: 'pointer',
                    height: 'var(--height2)',
                    width: 'auto',
                }}
                onClick={() => setSelectedItem(item)} // Set the selected item on click
            >
                <img src={`${process.env.REACT_APP_API_URL}${item.Image}`} style={{ width: 'var(--Im)' }} height="auto" alt="" />
            </div>
        </div>
    );

    useEffect(() => {
        const init = async () => {
            try {
                const res = await RequestManager.fetchCollective();
                setCollective(TransformMany(res?.data) ?? []);
            } catch (err) {
                console.error("Error in fetching collectives", err);
            } finally {
                setIsLoading(false); // Set loading to false after data is fetched
            }
        };
        init();
    }, []);

    useEffect(() => {
        if (!isLoading) { // Ensure Swipers are ready before manipulating
            // Move to the next slide after the component mounts
            const initialSlideChange = () => {
                if (swiperTopRef.current && swiperTopRef.current.swiper) {
                    swiperTopRef.current.swiper.slideNext(); // Manually trigger slide to the next
                }
                if (swiperBottomRef.current && swiperBottomRef.current.swiper) {
                    swiperBottomRef.current.swiper.slideNext(); // Manually trigger slide to the next
                }
            };
            setTimeout(initialSlideChange, 1000); // Wait 1 second before moving to the next slide
        }
    }, [isLoading]);

    const closePopup = () => {
        setSelectedItem(null); // Close the popup by setting selectedItem to null
    };

    return (
        <div className="container position-relative">
            {isLoading ? (
                // Loading Spinner
                <div className="spinner-container">
                    <div className="spinner"></div>
                </div>
            ) : (
                <>
                    <Swiper
                        ref={swiperTopRef}
                        modules={[Autoplay]}
                        slidesPerGroup={1}
                        spaceBetween={30}
                        loop={true}
                        autoplay={{
                            delay: 1000,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true,
                        }}
                        speed={5000}
                        style={{ marginTop: '30px' }} // Add some spacing between the rows
                        breakpoints={{
                            // when window width is >= 640px (mobile)
                            0: {
                                slidesPerView: 2, // 2 slides per view on mobile
                                spaceBetween: 100, // optional, can reduce space on mobile
                            },
                            // when window width is >= 1024px (tablets and above)
                            1024: {
                                slidesPerView: 4, // 4 slides per view on larger screens
                                spaceBetween: 30,
                            },
                        }}
                    >
                        {collective.filter((_, index) => index % 2 === 0).map((item, index) => (
                            <SwiperSlide key={index}>
                                {renderCollective(item, index)}
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    {/* Bottom Row */}
                    <Swiper
                        ref={swiperBottomRef}
                        modules={[Autoplay]}
                        slidesPerGroup={1}
                        loop={true}
                        autoplay={{
                            delay: 1000,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true,
                        }}
                        speed={5000}
                        style={{ marginTop: '30px' }} // Add some spacing between the rows
                        breakpoints={{
                            // when window width is >= 640px (mobile)
                            0: {
                                slidesPerView: 2, // 2 slides per view on mobile
                                spaceBetween: 100, // optional, can reduce space on mobile
                            },
                            // when window width is >= 1024px (tablets and above)
                            1024: {
                                slidesPerView: 4, // 4 slides per view on larger screens
                                spaceBetween: 30,
                            },
                        }}
                    >
                        {collective.filter((_, index) => index % 2 !== 0).map((item, index) => (
                            <SwiperSlide key={index}>
                                {renderCollective(item, index)}
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </>
            )}

            {/* Popup Modal */}
            {selectedItem && (
                <div className="popup">
                    <div className="popup-content pb-5">
                        <div className="row">
                            <div className="col-12 d-flex align-items-center justify-content-center">
                                <button className="close-button d-flex mt-4 align-items-center justify-content-center" onClick={closePopup}>
                                    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="1.86621" y="0.335205" width="29.2732" height="2.5455" transform="rotate(45 1.86621 0.335205)" fill="white" />
                                        <rect x="0.59375" y="20.6992" width="29.2732" height="2.5455" transform="rotate(-45 0.59375 20.6992)" fill="white" />
                                    </svg>
                                </button>
                            </div>
                            <div className="col-12 py-5" style={{ backgroundColor: `${selectedItem.Color}`, borderRadius: '10px 10px 0px 0px' }}>

                            </div>
                            <div className="col-12 mt-5">
                                <div className="row">

                                    <div className="col-12 col-lg-4">
                                        <div className="row h-100">
                                            <div className="col-12 d-flex justify-content-center align-items-center">

                                                <img className='Im' src={`${process.env.REACT_APP_API_URL}${selectedItem.Image}`} alt={selectedItem.name} />
                                            </div>
                                            {selectedItem.Link && (
                                                <div className="col-12 mt-5 d-flex align-items-start justify-content-center">

                                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M4.99713 7.82602C6.13013 6.69302 8.10613 6.69302 9.23913 7.82602L9.94613 8.53302L11.3601 7.11902L10.6531 6.41202C9.71013 5.46802 8.45413 4.94702 7.11813 4.94702C5.78213 4.94702 4.52613 5.46802 3.58313 6.41202L1.46113 8.53302C0.525422 9.47174 0 10.7431 0 12.0685C0 13.3939 0.525422 14.6653 1.46113 15.604C1.925 16.0686 2.47609 16.4369 3.08274 16.6878C3.68939 16.9387 4.33964 17.0672 4.99613 17.066C5.65279 17.0674 6.30324 16.9389 6.91008 16.688C7.51692 16.4371 8.06817 16.0687 8.53213 15.604L9.23913 14.897L7.82513 13.483L7.11813 14.19C6.5546 14.751 5.7918 15.066 4.99663 15.066C4.20146 15.066 3.43866 14.751 2.87513 14.19C2.31363 13.6267 1.99833 12.8639 1.99833 12.0685C1.99833 11.2732 2.31363 10.5103 2.87513 9.94702L4.99713 7.82602Z" fill="black" />
                                                        <path d="M8.5321 1.46195L7.8251 2.16895L9.2391 3.58295L9.9461 2.87595C10.5096 2.31494 11.2724 1.99998 12.0676 1.99998C12.8628 1.99998 13.6256 2.31494 14.1891 2.87595C14.7506 3.43922 15.0659 4.20212 15.0659 4.99745C15.0659 5.79279 14.7506 6.55568 14.1891 7.11895L12.0671 9.23995C10.9341 10.373 8.9581 10.373 7.8251 9.23995L7.1181 8.53295L5.7041 9.94695L6.4111 10.654C7.3541 11.598 8.6101 12.119 9.9461 12.119C11.2821 12.119 12.5381 11.598 13.4811 10.654L15.6031 8.53295C16.5388 7.59424 17.0642 6.32287 17.0642 4.99745C17.0642 3.67203 16.5388 2.40067 15.6031 1.46195C14.6646 0.525758 13.3932 0 12.0676 0C10.742 0 9.47056 0.525758 8.5321 1.46195Z" fill="black" />
                                                    </svg> &nbsp;

                                                    <a
                                                        href={selectedItem.Link.startsWith('http') ? selectedItem.Link : `https://${selectedItem.Link}`}
                                                        style={{ color: 'inherit' }}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {selectedItem.Link}
                                                    </a>

                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-8 mt-5 mt-lg-0">
                                        <div className="row">
                                            <div className="col-12 text-start" style={{ fontSize: '32px' }}>
                                                <strong>
                                                    {selectedItem.Title}
                                                </strong>
                                            </div>
                                            <div dangerouslySetInnerHTML={{ __html: selectedItem.Description }} className="col-12 mt-5 text-start" style={{ maxHeight: '30vh', overflowY: 'scroll' }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Collective;
