import React from 'react'
// import UN from '../Home/UN'
import './Footer.css'
import { useNavigate } from 'react-router-dom'

function Footer() {

    const navigate = useNavigate();
    return (
        <div className='container py-5'>
            <div className="row" style={{ fontSize: 'var(--footer)' }}>
                <div className="col-12" >
                    Unlock Your <span style={{ color: "var(--green)" }}>un</span>lmtd Potential
                </div>

            </div>
            <div className="row mt-5" style={{ fontSize: "var(--footer2)", fontWeight: '200' }}>
                <div className="col ml-auto" >
                    <a className='Email' href="mailto:hello@weareunlmtd.com" style={{ textDecoration: 'none' }}>
                        hello@weareunlmtd.com
                    </a>
                </div>
                {/* <div className="col-auto">
                    LinkedIn
                </div>
                <div className="col-auto">
                    Instagram
                </div> */}
            </div>
            <div className="row mt-5" style={{ fontSize: "var(--footerText)", fontWeight: '200' }}>
                <div className="col ml-auto" >
                    2024 © weareunlmtd. All rights reserved
                </div>
                <div onClick={() => navigate("/terms")} className="col-12 col-lg-auto" style={{cursor:'pointer'}}>

                    Terms & Conditions
                </div>
                <div className="col-auto d-none d-lg-flex">
                    |
                </div>
                <div onClick={() => navigate("/privacy")} className="col-12 col-lg-auto" style={{cursor:'pointer'}}>

                    Privacy Policy
                </div>
                <div className="col-auto d-none d-lg-flex">
                    |
                </div>
                <div onClick={() => navigate("/cookies")} className="col-12 col-lg-auto" style={{cursor:'pointer'}}>
                    Cookie Disclaimer
                </div>

            </div>
        </div>
    )
}

export default Footer