import Load from './Media/unlmtd.gif'
import React from 'react';

const Loading = () => {
  return (
    <div className="loading-container">
      <img src={Load} alt="Loading..." className="loading-gif" />
    </div>
  );
};

export default Loading;
