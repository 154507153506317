import * as React from "react";
const UNmobile = (props) => (
    <svg
        width={34}
        height={23}
        viewBox="0 0 34 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M31.6046 2.48359C30.4818 1.37077 28.7607 0.814358 26.4413 0.814358C24.6489 0.79121 22.9056 1.39949 21.5167 2.53263C20.2376 3.63053 19.6279 4.93735 19.0033 6.47015V1.0787H14.8206V13.6843C14.674 14.4261 14.4243 15.1436 14.0787 15.8162C13.5102 16.8721 12.7918 17.614 11.9234 18.0418C10.9917 18.4838 9.96982 18.7027 8.9388 18.6814C7.3854 18.6814 6.27258 18.3388 5.60034 17.6538C4.9281 16.9688 4.59127 15.8737 4.58985 14.3686V1.06591H0.524414V15.0743C0.524414 17.3227 1.06306 19.004 2.14036 20.1182C3.21765 21.2325 4.91601 21.7889 7.23546 21.7875C8.45182 21.8038 9.65878 21.5724 10.7829 21.1074C12.8401 20.2376 13.9913 18.2806 14.5285 16.2041C14.6202 15.8545 14.7055 15.5049 14.7907 15.1532C14.7907 15.1318 14.8057 15.1062 14.8121 15.0828V21.3526H18.9947V8.18202C19.0933 7.80449 19.2194 7.43469 19.3721 7.07559C19.8823 5.8917 20.578 5.06241 21.4591 4.58772C22.3403 4.11303 23.4297 3.87569 24.7273 3.87569C25.8159 3.87569 26.6914 4.01781 27.3537 4.30205C27.9791 4.54654 28.4886 5.0188 28.7799 5.6238C29.0952 6.37061 29.2409 7.1781 29.2063 7.98802V21.3526H33.2909V7.31862C33.2909 5.20809 32.7288 3.59641 31.6046 2.48359Z"
            fill="#83C909"
        />
    </svg>
);
export default UNmobile;
