import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import { RequestManager } from '../Requests/RequestManager';
import Background from './Media/Subtract.png';
import TransformMany from '../Transformer/TransformMany';
import './Team.css'

function Team() {
    const [team, setTeam] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const swiperRef = useRef(null);

    const renderCollective = (item, index) => (
        <div key={index} className="d-flex row align-items-center justify-content-end" style={{
            backgroundImage: `url(${Background})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'left',
            height: 'var(--team)',
        }}>
            <div className="d-flex col-10 align-items-center justify-content-center"
                style={{
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    cursor: 'pointer',
                }}
                onClick={() => setSelectedItem(item)}
            >
                <div className="row">
                    <div className="d-flex align-items-center" style={{ position: 'absolute', top: 0, left: 0 }}>
                        <img className='Image2' src={`${process.env.REACT_APP_API_URL}${item.Image}`} height="auto" style={{ borderRadius: '50%', }} alt="" />
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: item.Name }} className="col-12 Hovv  py-5 d-flex flex-column align-items-center justify-content-start mx-3 mx-lg-5 mt-0 mt-lg-5" style={{ fontSize: 'var(--name)', lineHeight: '20px' }}></div>
                </div>
            </div>
        </div>
    );

    useEffect(() => {
        const init = async () => {
            try {
                const res = await RequestManager.fetchTeam();
                setTeam(TransformMany(res?.data) ?? []);
            } catch (err) {
                console.error("Error in fetching collectives", err);
            }
        };
        init();
    }, []);

    useEffect(() => {
        // On mount, move the slider to the next slide manually after a short delay
        const timeout = setTimeout(() => {
            if (swiperRef.current && swiperRef.current.swiper) {
                swiperRef.current.swiper.slideNext(); // Manually trigger slide to the next
            }
        }, 1000); // 1 second delay before sliding to the next slide

        return () => clearTimeout(timeout); // Cleanup on unmount
    }, [team]);

    const closePopup = () => {
        setSelectedItem(null); // Close the popup by setting selectedItem to null
    };

    return (
        <div className="container">
            <Swiper
                ref={swiperRef} // Attach swiperRef to the Swiper instance
                modules={[Autoplay]}
                slidesPerGroup={1}
                loop={true}
                autoplay={{
                    delay: 1000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                }}
                speed={5000}
                style={{ marginTop: '30px' }}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    1024: {
                        slidesPerView: 4,
                        spaceBetween: 50,
                    },
                }}
            >
                {team.map((item, index) => (
                    <SwiperSlide key={index}>
                        {renderCollective(item, index)}
                    </SwiperSlide>
                ))}
            </Swiper>

            {selectedItem && (
                <div className="popup">
                    <div className="popup-content pb-5">
                        <div className="row">
                            <div className="col-12 d-flex align-items-centers">
                                <button className="close-button d-flex mt-4 align-items-center justify-content-center" onClick={closePopup}>
                                    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="1.86621" y="0.335205" width="29.2732" height="2.5455" transform="rotate(45 1.86621 0.335205)" fill="var(--blue)" />
                                        <rect x="0.59375" y="20.6992" width="29.2732" height="2.5455" transform="rotate(-45 0.59375 20.6992)" fill="var(--blue)" />
                                    </svg>
                                </button>
                            </div>

                            <div className="col-12 mt-5">
                                <div className="row">
                                    <div className="col-12 col-lg-4">
                                        <div className="row h-100">
                                            <div className="col-12 d-flex justify-content-center align-items-start" style={{
                                                backgroundImage: `url(${Background})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'var(--Im)',
                                                backgroundPosition: '70px top'
                                            }}>
                                                <img className='Im' src={`${process.env.REACT_APP_API_URL}${selectedItem.Image}`} alt={selectedItem.name} style={{ borderRadius: '50%' }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-8 mt-5 mt-lg-0">
                                        <div className="row">
                                            <div dangerouslySetInnerHTML={{ __html: selectedItem.FullName }} className="col-12 text-start d-flex align-items-center" style={{ fontSize: '32px', fontWeight: 'bold' }}></div>
                                            <div className="col-12 text-start" style={{ fontSize: '18px', color: '#191919', fontWeight: 'lighter' }}>{selectedItem.Title}</div>
                                            <div dangerouslySetInnerHTML={{ __html: selectedItem.Description }} className="col-12 mt-5 text-start" style={{ maxHeight: '30vh', overflowY: 'scroll' }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Team;
