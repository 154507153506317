import React from 'react';
import { useInView } from 'react-intersection-observer';


function FadeInComponent(props) {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0,
    });

    return (
        <div
            ref={ref}
            className={`text-white fade-in-element ${inView ? 'fade-in-visible' : ''}`}
            style={{ fontSize: 'var(--service)' }}
        >
            {props.Text}
            <div className="col-auto w-50">

                <hr style={{ borderWidth: '2px', color: 'var(--green)', opacity: '1' }} />
            </div>
        </div>
    );
}

export default FadeInComponent;