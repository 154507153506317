import React from 'react';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';

const CountUpComponent = ({ start = 0, end, duration = 2 }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <div ref={ref}>
      {inView && <CountUp start={start} end={end} duration={duration} />}
    </div>
  );
};

export default CountUpComponent;
