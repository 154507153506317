import React, { useEffect, useState } from 'react';
import Background from './Media/Background.png';
import './Home.css';
import UN from './UN';
import Back from './Media/Back.png'
import CountUpComponent from './CountUpComponent';
import Line from './Media/line1.png'
import Services from '../Service/Services';
import Collective from '../Collective/Collective';
import Team from '../Team/Team';
import Logos from '../Logos/Logos';
import Back2 from './Media/Back2.png'
import { useInView } from 'react-intersection-observer';
import UNmobile from './UNmobile';
import { AxiosInstance } from '../Axios/Axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Home() {
    const words = [
        "LMTD", "DERSTANDABLE", "STOPPABLE", "AFRAID", "CONVENTIONAL",
        "WAVERING", "PARALLELED", "COMPROMISING", "BELIEVABLE", "EXPECTED",
        "BEATABLE", "LMTD"
    ];
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await AxiosInstance.post('/api/emails', {
                data: {
                    Full_Name: formData.name,
                    Email: formData.email,
                    Phone: formData.phone,
                    Message: formData.message
                }
            });

            if (response.status === 200) {
                toast.success('Form submitted successfully!'); // Success toast

                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    message: ''
                });

            } else {
                toast.error('Failed to submit form!'); // Error toast
            }
        } catch (error) {
            toast.error('Error submitting form!'); // Error toast
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };


    const [currentIndex, setCurrentIndex] = useState(0);
    const [form, setForm] = useState(false);

    const [isTransitioning, setIsTransitioning] = useState(true);
    const closePopup = () => {
        setForm(false);
    }
    const { ref: firstRef, inView: inView1 } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });

    const { ref: secondRef, inView: inView2 } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });

    const { ref: thirdRef, inView: inView3 } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });
    const { ref: fourthRef, inView: inView4 } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });
    const { ref: fifthRef, inView: inView5 } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });
    const { ref: sixthRef, inView: inView6 } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });
    const { ref: seventhRef, inView: inView7 } = useInView({
        triggerOnce: true,
        threshold: 0.5,
    });


    useEffect(() => {
        const interval = setInterval(() => {
            if (currentIndex === words.length - 1) {
                setIsTransitioning(false);
                setTimeout(() => {
                    setCurrentIndex(0);
                    setTimeout(() => {
                        setIsTransitioning(true);
                    }, 20);
                }, 800);
            } else {
                setCurrentIndex(prevIndex => prevIndex + 1);
            }
        }, 2000);

        return () => clearInterval(interval);
    }, [currentIndex, words.length]);

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    };


    useEffect(() => {
        const handleHashChange = () => {
            const hash = window.location.hash;
            if (hash) {
                switch (hash) {
                    case '#about':
                        scrollToSection('about');
                        break;
                    case '#section2':
                        scrollToSection('section2');
                        break;
                    case '#section3':
                        scrollToSection('section3');
                        break;
                    case '#section4':
                        scrollToSection('section4');
                        break;
                    case '#section5':
                        scrollToSection('section5');
                        break;
                    case '#section6':
                        scrollToSection('section6');
                        break;
                    case '#section7':
                        scrollToSection('section7');
                        break;
                    default:
                        break;
                }
            }
        };

        // Handle initial load
        handleHashChange();

        // Add event listener for hash changes
        window.addEventListener('hashchange', handleHashChange);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);


    return (
        <div className='container-fluid'>
            <div className="row d-flex align-items-center" style={{
                height: '100vh',
                backgroundImage: `url(${Background})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            }}>
                <div className="container mt-5">
                    <div className="row">

                        <div className="col-12 Margin" style={{ color: 'white', fontSize: 'var(--title)' }}>
                            WE ARE<br />
                        </div>
                        <div className="col-12 d-flex text-white align-items-center">




                            <UN style={{ width: 'var(--width)' }} />


                            <div className="word-slider" style={{ fontSize: 'var(--scroll)' }}>
                                <div
                                    className={`words ${isTransitioning ? 'fade-in' : 'fade-out'}`}
                                    style={{
                                        transform: window.innerWidth < 680 ? `translateY(-${currentIndex * `50`}px)` : `translateY(-${currentIndex * `155`}px)`,
                                        transition: isTransitioning ? 'transform 0.8s cubic-bezier(0.25, 1.25, 0.5, 1.0)' : 'none'
                                    }}
                                >
                                    {words.map((word, index) => (
                                        <div key={index}>{word}</div>
                                    ))}
                                </div>
                            </div>


                        </div>
                        <div className="col-12 col-lg-5 mt-5 d-flex align-items-center" style={{ fontSize: 'var(--text)' }}>
                            <button onClick={() => setForm(true)} className='d-flex py-3 align-items-center justify-content-center Button'>

                                <span>un</span>lock Potential
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div id='about' className="container d-flex align-items-start justify-content-center flex-column" style={{
                height: '100vh',
                backgroundImage: `url(${Line})`,
                backgroundPosition: 'center right',
                backgroundSize: '',
                backgroundRepeat: 'no-repeat'
            }}>
                <div ref={firstRef} className={`row line d-flex fade-left ${inView1 ? 'fade-in-left' : ''} align-items-center`} style={{ fontSize: 'var(--subTitle)', color: 'var(--black)' }}>
                    <div className="col-12" >
                        Revolutionizing the consultancy <br /> and management experience,
                    </div>
                    <div className="col-12 d-none d-lg-flex align-items-center">
                        <UN style={{ width: 'var(--width2)' }} />
                        <strong className='mx-0 mx-lg-1' style={{ color: 'var(--sec)', fontSize: 'var(--subTitle)' }}>
                            APOLOGETICALLY
                        </strong>
                    </div>
                    <div className="col-12 d-flex d-lg-none align-items-center">
                        <UNmobile style={{ width: 'var(--width2)' }} />
                        <strong className='mx-0 mx-lg-1' style={{ color: 'var(--sec)', fontSize: 'var(--subTitle)' }}>
                            APOLOGETICALLY
                        </strong>
                    </div>
                </div>
                <div ref={secondRef} className={`row mt-5 fade-right ${inView2 ? 'fade-in-right' : ''}`} style={{ fontSize: 'var(--text)' }}>
                    <div className="col-12">
                        <div className="row d-flex flex-column-reverse flex-lg-row justify-content-start">
                            <div className="col-12 col-lg-7">

                                <div className="row mt-5 mt-lg-0">


                                    <div className="col-12 col-lg-4">
                                        <div className="row">
                                            <div className="col-12 d-flex" style={{ fontSize: 'var(--subTitle)', color: 'var(--green)', fontWeight: 'bold' }}>
                                                <CountUpComponent end={30} />+
                                            </div>
                                            <div className="col-12">
                                                Years on the market
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4">
                                        <div className="row">
                                            <div className="col-12 d-flex" style={{ fontSize: 'var(--subTitle)', color: 'var(--green)', fontWeight: 'bold' }}>
                                                <CountUpComponent end={400} />+
                                            </div>
                                            <div className="col-12">
                                                Satisfied Customers
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4">
                                        <div className="row">
                                            <div className="col-12 d-flex" style={{ fontSize: 'var(--subTitle)', color: 'var(--green)', fontWeight: 'bold' }}>
                                                <CountUpComponent end={10} />+
                                            </div>
                                            <div className="col-12">
                                                Countries
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-5  mt-lg-0">
                                At <strong>Unlmtd</strong>, our collective success starts with you.<br /> We work with top independent agencies and industry experts to unlock growth, innovation and strategic business opportunities that enable you to excel in the competitive global marketplace.
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="row" id='services'>
                <Services />
            </div>
            <div className="container-fluid" style={{ backgroundImage: `url(${Back2})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>


                <div className="container MT " style={{ paddingTop: '', paddingBottom: '10rem' }}>
                    <div ref={thirdRef} className={`row fade-left ${inView3 ? 'fade-in-left' : ''}`}>

                        <div className="col-12 mt-5">
                            <div className="row d-flex flex-column justify-content-center" style={{ fontSize: 'var(--subTitle)', lineHeight: 'var(--height4)' }}>
                                <div className="col-12 ">

                                    This is what an
                                </div>
                                <div className="col-12 d-none d-lg-flex align-items-center ">
                                    <UN style={{ width: 'var(--width2)', height: '' }} /><strong>PARALLELED</strong>
                                </div>
                                <div className="col-12 d-flex d-lg-none align-items-center ">
                                    <UNmobile style={{ width: 'var(--width2)', height: '28px' }} /><strong>PARALLELED</strong>
                                </div>
                                <div className="col-12">
                                    ecosystem looks like
                                </div>

                            </div>
                        </div>
                    </div>
                    <div ref={fourthRef} className={`row mt-5 fade-right ${inView4 ? 'fade-in-right' : ''}`}>
                        <div className="col-6 d-none d-lg-flex">

                        </div>
                        <div className="col-12 col-lg-6" style={{ fontSize: 'var(--text)' }}>
                            Choosing <strong>Unlmtd</strong> means working with top talent who genuinely care about your success. Our approachable and knowledgeable team is dedicated to achieving your goals with ease and confidence.
                        </div>
                    </div>

                    <div id='members' className="row mt-5 pb-5" style={{ height: '', borderBottom: '1px solid black' }}>
                        <Collective />
                    </div>
                    <div className="row mt-5" >
                        <Team />
                    </div>
                </div>
            </div>
            <div className="container MT ">
                <div ref={fifthRef} className={`row fade-left ${inView5 ? 'fade-in-left' : ''}`} style={{ fontSize: 'var(--subTitle)', lineHeight: '60px' }}>
                    <div className="col-12 d-none d-lg-flex align-items-center Margin" >
                        We're&nbsp;<UN style={{ width: 'var(--width2)' }} /><strong>MATCHED</strong>
                    </div>
                    <div className="col-12 d-flex d-lg-none align-items-center" >
                        We're&nbsp;<UNmobile style={{ width: 'var(--width2)', height: '28px' }} /><strong>MATCHED</strong>
                    </div>
                    <div className="col-12 ">
                        in our collective experience
                    </div>
                </div>

                <div id='clients' className="row mt-5">
                    <Logos />
                </div>
            </div>
            <div className="container MT" style={{ marginBottom: '10rem', marginTop: '' }}>
                <div className="row" style={{ height: '50vh' }}>
                    <div className="col-12 " style={{
                        backgroundImage: `url(${Back})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        borderRadius: '20px'
                    }}>
                        <div className="row h-100  d-flex align-items-center">
                            <div className="col-1 d-none d-lg-flex">

                            </div>
                            <div id='contact' ref={sixthRef} className={`col-12 col-lg-6 fade-left ${inView6 ? 'fade-in-left' : ''} `} style={{ paddingLeft: '' }}>
                                <div className="row  d-flex align-items-center" style={{ fontSize: 'var(--subTitle)', color: 'white', lineHeight: '45px' }}>
                                    <div className="col-12 d-flex align-items-center Margin">
                                        Ready to become
                                    </div>
                                    <div className="col-12 d-flex align-items-center">
                                        <UN style={{ width: 'var(--width2)' }} /><strong>STOPPABLE</strong>?
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12" style={{ fontSize: "var(--text)", color: 'white' }}>
                                        If you’re looking to collaborate and grow your business capabilities, we’d be happy to support you with the power of our collective expertise.
                                    </div>
                                </div>
                            </div>
                            <div ref={seventhRef} className={`col-12 col-lg-4 col-xl-5 d-flex align-items-center fade-right justify-content-center ${inView7 ? 'fade-in-right' : ''}`}>
                                <div className="row w-100">
                                    <div className="col-12 d-flex align-items-center justify-content-center text-center">
                                        <button onClick={() => setForm(true)} className='d-flex py-3 flex-row align-items-center justify-content-center Button2'>

                                            <span>un</span>lock Potential
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                form && (
                    <div className="popup">
                        <div className="popup-content pb-5">
                            <div className="row p-0 py-4 p-lg-5" style={{

                            }}>
                                <div className="col-12 d-flex align-items-centers">
                                    <button className="close-button d-flex mt-4 align-items-center justify-content-center" onClick={closePopup}><svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="1.86621" y="0.335205" width="29.2732" height="2.5455" transform="rotate(45 1.86621 0.335205)" fill="var(--blue)" />
                                        <rect x="0.59375" y="20.6992" width="29.2732" height="2.5455" transform="rotate(-45 0.59375 20.6992)" fill="var(--blue)" />
                                    </svg>
                                    </button>
                                </div>
                                <div className="col-12 d-flex align-items-center justify-content-start">
                                    <svg width="58" height="40" viewBox="0 0 58 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.68475 30.4469C9.24772 31.6137 10.2314 32.5242 11.4382 32.9954C12.7107 33.532 14.3986 33.8004 16.5019 33.8004C19.0107 33.8004 21.1118 33.3418 22.8053 32.4247C24.4988 31.5076 25.8396 29.9096 26.8278 27.6307C27.8132 25.3491 28.3707 22.197 28.5004 18.1745H29.7088C29.5318 23.5392 28.7715 27.8091 27.4278 30.9842C26.0842 34.1593 24.2291 36.4054 21.8624 37.7225C19.493 39.041 16.6092 39.701 13.2111 39.7024C8.74121 39.7024 5.42118 38.6292 3.25103 36.4827C1.08089 34.3363 -0.00278223 31.2288 5.36421e-06 27.1603V15.1555H7.84639V25.8871C7.84639 27.7645 8.12584 29.2844 8.68475 30.4469Z" fill="#82C809" />
                                        <path d="M49.3151 9.25552C48.7528 8.08821 47.7688 7.17749 46.5616 6.70696C45.2891 6.16895 43.6012 5.90065 41.498 5.90204C38.9891 5.90204 36.888 6.35989 35.1945 7.27562C33.501 8.19134 32.1602 9.79003 31.172 12.0717C30.1866 14.3533 29.6291 17.5054 29.4994 21.5279H28.291C28.468 16.1632 29.2283 11.8933 30.572 8.7182C31.9156 5.54313 33.7707 3.29634 36.1374 1.9778C38.5069 0.660664 41.3906 0.0013938 44.7887 0C49.2586 0 52.5786 1.07323 54.7488 3.21968C56.9189 5.36612 58.0026 8.4729 57.9998 12.54V24.7204H50.1534V13.8153C50.1534 11.9379 49.874 10.4179 49.3151 9.25552Z" fill="#82C809" />
                                        <path d="M50.1534 25.887V12.54H57.9998V27.1603C57.9998 31.2288 56.9161 34.3362 54.7488 36.4827C52.5814 38.6291 49.2614 39.7024 44.7887 39.7024C41.392 39.7024 38.5083 39.0424 36.1374 37.7225C33.7666 36.4025 31.9114 34.1564 30.572 30.9842C29.2297 27.8077 28.4694 23.5378 28.291 18.1745H29.4994C29.6388 22.1997 30.1963 25.3518 31.172 27.6307C32.1477 29.9095 33.4899 31.5068 35.1987 32.4226C36.8991 33.3383 39.0003 33.7968 41.5021 33.7982C43.604 33.7982 45.2919 33.5299 46.5658 32.9933C47.771 32.5212 48.7531 31.6108 49.3151 30.4447C49.8754 29.2837 50.1548 27.7645 50.1534 25.887Z" fill="#82C809" />
                                        <path d="M7.84638 13.8153V24.7204H0V12.54C0 8.4743 1.08367 5.36752 3.25103 3.21968C5.41838 1.07184 8.73841 -0.00139244 13.2111 1.35583e-06C16.6092 1.35583e-06 19.4929 0.659272 21.8624 1.9778C24.2319 3.29634 26.087 5.54314 27.4278 8.71821C28.7701 11.8933 29.5304 16.1632 29.7088 21.5279H28.5004C28.361 17.504 27.8035 14.3519 26.8278 12.0717C25.8521 9.79143 24.5113 8.19274 22.8053 7.27562C21.1063 6.3599 19.0051 5.90204 16.5019 5.90204C14.4014 5.90204 12.7135 6.17035 11.4382 6.70696C10.2325 7.17838 9.25022 8.08899 8.68893 9.25552C8.12584 10.4179 7.84499 11.9379 7.84638 13.8153Z" fill="#82C809" />
                                    </svg>

                                </div>
                                <div className="col-12 d-flex align-items-center" style={{ fontSize: 'var(--subTitle)' }}>
                                    <UN style={{ width: 'var(--width2)' }} />lock Potential
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="col-12 mt-0 mt-lg-5">
                                        <div className="row">
                                            <div className="col-12 col-lg-4">
                                                <div className="row">
                                                    <div className="col-12 text-start">
                                                        <label htmlFor="name"> <strong>Full Name</strong> </label>
                                                    </div>
                                                    <div className="col-12 d-flex align-items-center justify-content-start">
                                                        <input type="text" className='w-100 p-3' name="name" value={formData.name} onChange={handleChange} required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-4">
                                                <div className="row">
                                                    <div className="col-12 text-start">
                                                        <label htmlFor="email"> <strong>Email Address</strong> </label>
                                                    </div>
                                                    <div className="col-12 d-flex align-items-center justify-content-start">
                                                        <input type="email" className='w-100 p-3' name="email" value={formData.email} onChange={handleChange} required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-4">
                                                <div className="row">
                                                    <div className="col-12 text-start">
                                                        <label htmlFor="phone"> <strong>Phone Number</strong> </label>
                                                    </div>
                                                    <div className="col-12 d-flex align-items-center justify-content-start">
                                                        <input type="tel" className='w-100 p-3' name="phone" value={formData.phone} onChange={handleChange} required />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mt-0 mt-lg-5 px-0 mx-0 w-100">
                                            <div className="col-12 px-0 text-start">
                                                <label htmlFor="message"> <strong>Message</strong> </label>
                                            </div>
                                            <div className="col-12 px-0 mx-0 w-100 d-flex align-items-center justify-content-end">
                                                <textarea className='w-100 p-3 mx-0' rows="5" name="message" value={formData.message} onChange={handleChange} required />
                                            </div>
                                        </div>
                                        <div className="row mt-5">
                                            <div className="col-12">
                                                <button type="submit" className='px-2 px-lg-5 py-3 Submit' disabled={loading} style={{
                                                    color: 'white',
                                                    border: 'none',
                                                    backgroundColor: loading ? 'gray' : 'var(--green)', // Change color when loading
                                                    borderRadius: '10px',
                                                    fontSize: '22px',
                                                    position: 'relative'
                                                }}>
                                                    {loading ? (
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> // Show spinner
                                                    ) : (
                                                        'Submit'
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>



                            </div>
                        </div>
                    </div>
                )
            }
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
        </div >
    );
}

export default Home;
