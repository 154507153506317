import * as React from "react";
const UN = (props) => (
  <svg
    width={180}
    height={115}
    viewBox="0 0 180 115"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M170.036 9.17555C163.902 3.09631 154.5 0.056703 141.829 0.056703C132.038 -0.0697494 122.514 3.25322 114.927 9.44343C107.939 15.4411 104.608 22.5802 101.196 30.9537V1.5008H78.3465V70.3639C77.5457 74.4161 76.1815 78.3361 74.2937 82.01C71.1881 87.7787 67.2633 91.8315 62.5195 94.1685C57.4296 96.5829 51.8474 97.7791 46.215 97.6623C37.7289 97.6623 31.6497 95.7912 27.9773 92.0489C24.3049 88.3066 22.4649 82.3245 22.4571 74.1023V1.43093H0.248047V77.9572C0.248047 90.2399 3.19064 99.4248 9.07578 105.512C14.9609 111.599 24.2389 114.638 36.9098 114.631C43.5547 114.72 50.1482 113.456 56.2889 110.916C67.5274 106.164 73.8162 95.4729 76.751 84.1296C77.2518 82.2196 77.7176 80.3097 78.1835 78.3881C78.1835 78.2716 78.265 78.1319 78.3 78.0038V112.255H101.15V40.3055C101.688 38.2431 102.377 36.2229 103.211 34.2612C105.998 27.7937 109.799 23.2634 114.612 20.6702C119.426 18.077 125.377 16.7804 132.466 16.7804C138.413 16.7804 143.196 17.5568 146.814 19.1096C150.23 20.4452 153.014 23.0251 154.605 26.3302C156.328 30.4099 157.123 34.8212 156.934 39.2457V112.255H179.248V35.5888C179.248 24.0592 176.177 15.2548 170.036 9.17555Z"
      fill="#83C909"
    />
    <path
      d="M101.195 1.50098H78.3457V112.208H101.195V1.50098Z"
      fill="#83C909"
    />
  </svg>
);
export default UN;
