import React, { useEffect, useState } from 'react';
import Slider from 'react-slick'; // Import the slick slider
import { RequestManager } from '../Requests/RequestManager';
import TransformMany from '../Transformer/TransformMany';
import 'slick-carousel/slick/slick.css'; // Import slick css
import 'slick-carousel/slick/slick-theme.css'; // Import slick theme css
import './Logos.css'; // Custom styles for additional tweaks

function Logos() {
    const [logo, setLogo] = useState([]);

    useEffect(() => {
        const init = async () => {
            try {
                const res = await RequestManager.fetchLogos();
                setLogo(TransformMany(res?.data) ?? []);
                console.log(res.data);
            } catch (err) {
                console.error("Error in fetching data", err);
            }
        };
        init();
    }, []);

    // Slick carousel settings
    const settings = {
        dots: true,
        infinite: true, // Infinite scroll enabled
        speed: 3000, // Adjust speed for smoothness
        slidesToShow: 7, // Show 7 logos at a time
        slidesToScroll: 7, // Scroll one slide at a time
        autoplay: true, // Enable autoplay
        autoplaySpeed: 5000, // Set speed to 0 for non-stop scroll
        cssEase: 'linear', // Smooth continuous scrolling
        rows: 3, // Set 3 rows
        responsive: [
            {
                breakpoint: 1024, // Adjust for tablet sizes
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    rows: 3,
                },
            },
            {
                breakpoint: 768, // Adjust for mobile sizes
                settings: {
                    slidesToShow: 2, 
                    slidesToScroll: 2,
                    rows: 3,
                    speed: 1000,
                    autoplaySpeed: 2000,
                    dots:false
                },
            },
        ],
    };

    return (
        <div className="logos-container my-5">
            <Slider {...settings}>
                {logo.map((item) => (
                    item.Gallery?.data.map((imageItem, index2) => (
                        <div key={index2} className="logo-wrapper px-5 d-flex align-items-center justify-content-center mt-5" style={{ height: '' }}>
                            <img
                                className="Image"
                                width="auto"
                                height="50px"
                                src={`${process.env.REACT_APP_API_URL}${imageItem.attributes.url}`}
                                alt={imageItem.attributes.alternativeText || 'Logo Image'}
                            />
                        </div>
                    ))
                ))}
            </Slider>
        </div>
    );
}

export default Logos;
