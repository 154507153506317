import { AxiosInstance } from "../Axios/Axios";



const endpoints = {
    fetchCollective: `/api/collectives?populate=*`,
    fetchTeam: `/api/teams?populate=*&sort[0]=Number`,
    fetchLogos: `/api/logos?populate=*`
}

export const RequestManager = {

    async fetchCollective() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchCollective);
            return response?.data;
        } catch (error) {
            return {
                success: false,
                error,
            };
        }
    },
    async fetchTeam() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchTeam);
            return response?.data;
        } catch (error) {
            return {
                success: false,
                error,
            };
        }
    },
    async fetchLogos() {
        try {
            const response = await AxiosInstance.get(endpoints.fetchLogos);
            return response?.data;
        } catch (error) {
            return {
                success: false,
                error,
            };
        }
    },


}